<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ENFP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            ENFP merupakan mereka yang memiliki kepribadian Extraversion, Intuition, Feeling dan Perception. Didunia ini hanya ada 6-8% di dunia. Menurut dimensinya Extraversion merupakan seseorang yang bisa berinteraksi dengan luas dan menikmatinya. Intuition yakni cenderung menyukai hal yang abstrak dibanding realita yang ada. Feeling yakni menggunakan pertimbangan pribadi dalam mengambil keputusan dibandingkan logika. Lalu terakhir adalah Perception, dimana mereka cenderung menahan pendapat dan menunda keputusan untuk mencapai kesepakatan bersama.
          </p>
          <p>
            Ciri kepribadian ENFP adalah:
            <ul>
              <li> Berorientasi pada proyek</li>
              <li> Cerdas dan mampu serta hangat sehingga membuat banyak orang tertarik.</li>
              <li> Sangat intuitif dan perseptif tentang orang lain.</li>
              <li> Dapat bekerja sama dan memiliki keterampilan komunikasi verbal yang baik.</li>
              <li> Cenderung menempatkan kebutuhan orang lain dibanding dirinya sendiri.</li>
            </ul>
          </p>
          <p>
            Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajar fokus dan juga disiplin, tegas serta konsisten.
              </li>
              <li>
                Pikirkan kebutuhan sendiri karena memikirkan diri sendiri juga penting.
              </li>
              <li>
                Jangan terlalu boros dan coba untuk kelola uang dengan baik.
              </li>
              <li>
                Belajar untuk hadapi konflik dan permasalahan, jangan lari atau menutupi.
              </li>
              <li>
                Pekerjaan yang cocok yakni guru, penasihat, konsultan, pengusaha, reporter, pakar ilmu pengetahuan, insinyur dan seniman.
              </li>
            </ul>
          </p>
          <p>
            Pasangan yang cocok untuk ENFP adalah mereka yang INTJ atau INFJP. Namun hal ini tidak bisa menghalangi anda untuk memilih pasangan yang cocok tanpa melihat MBTI.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ENFP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ENFP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ENFP',
        slug: 'enfp'
      }
    }
  }
};
</script>

<style scoped>

</style>
